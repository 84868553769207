
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie"

const LoginBody = (props) => {
    //create a cookie class
    const cookies = new Cookies()

    const navigate = useNavigate()
    const { loginForm, setLoginForm, info, setInfo, setUserInfo, setLoginToken } = props
    const { username, password, remember } = loginForm
    const [loginState, setLoginState] = useState(true)

    const handleChange = (event) => {

        const { value, name } = event.target
        setLoginForm({ ...loginForm, [name]: value })
    }



    const handleLogin = async (event) => {
        event.preventDefault()
        setLoginState(false)
        //const url = "http://localhost:5000/login"
        const url = "https://it-support-ticket.onrender.com/login"
        try {
            await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(loginForm)
            }).then(result => result.json()).then(data => {
                setInfo({ ...info, loginInfo: data })
                setLoginState(true)
                if (data.message) {
                    setUserInfo(data.user)
                    setLoginToken(data.token)
                    cookies.set("token", { token: data.token })
                    cookies.set("username", { username: data.user })
                    cookies.set("email", { email: loginForm.username })
                    cookies.set("isAgent", { isAgent: data.isAgent })

                    setLoginForm({
                        username: "",
                        password: "",
                        remember: false,

                    })
                    navigate("/agentDashboard")
                }

            })
        } catch (err) {
            setInfo({ ...info, loginInfo: "An error occurred while logging in. Please try again later" })
            setLoginState(true)
            window.alert("Error from the server, try again later")
        }
    }

    return (
        <div className='flex w-full flex-col justify-between items-center py-8'>
            <div className='lg:flex bg-white lg:justify-between gap-4 lg:w-8/12 w-11/12 lg:p-8 px-4 py-8 shadow border border-slate-300 rounded-md basis '>
                <div className='grow-2 lg:border-r border-slate-400'>
                    <h1 className='text-xl font-bold mb-4'>Login to the support portal</h1>
                    <p className='text-sm'>Enter the details below</p>
                    <form className='py-4 login_form' onSubmit={handleLogin}>
                        <input className='block lg:w-10/12 w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 my-2 border-slate-400' type='text' placeholder='Username' name='username' value={username} onChange={handleChange} required />
                        <input className='block lg:w-10/12 w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 mt-4 my-2 border-slate-400' type='password' placeholder='Password' name='password' value={password} onChange={handleChange} required />
                        <div className='flex items-center my-2'>
                            <input id='remember' type='checkbox' name='remember' className='mr-2 text-2xl' checked={remember} onChange={handleChange} />
                            <label htmlFor='remember' className='text-sm' >Remember me on this computer</label>
                        </div>
                        <a href='/' className='text-sm'>Forgot password?</a>
                        <button className='button_1 block w-full lg:w-10/12 relative' type='submit'>{
                            loginState ? "Login" : <span className='w-full flex items-center justify-center '>
                                <div className='loading'></div>
                            </span>
                        }


                        </button>

                    </form>

                    {
                        info.loginInfo.error ? <em className='text-center px-2 py-1 block w-full lg:w-10/12 bg-red-100 rounded mt-2'>{info.loginInfo.error}</em> : null
                    }

                    <div className='mt-8 flex mx-4 flex-col'>

                        <div className=' flex lg:flex-row flex-col gap-2 mt-8 items-center '>
                            <h1 className=' text-xl lg:text-left text-center lg:w-5/12 w-full opacity-70 '>...or submit a new ticket</h1>
                            <p>
                                <a href='/new_ticket' className=' button_2' type='submit'>New Support Ticket</a>
                            </p>
                        </div>
                    </div>
                </div>


                <div className='grow-0 lg:mt-0 mt-8 lg:border-0 border-t border-slate-400 lg:pt-0 pt-8'>
                    <h1 className='text-xl font-bold mb-4'>Sign up with us</h1>
                    <a href='/signup'><p className='button_2'>Sign up</p></a>
                    <p className='text-sm'>Once you sign up, you will have complete access to our self service portal and you can use your account to raise support tickets and track their status.</p>
                </div>
            </div>

        </div>
    )
}

export default LoginBody